import {formatText, missingClass} from '~/lib/utils';

import clsx from 'clsx';

const sizes = {
  display: 'font-bold text-display',
  heading: 'font-medium text-heading font-headline',
  lead: 'font-bold text-lead',
  copy: 'font-medium text-copy font-sans',
  copyBold: 'text-copy font-sans font-bold',
  bodyMd: 'text-bodyMd font-sans',
  headlineSm: 'text-headlineSm font-bold font-headline',
  headlineMd: 'text-headlineMd font-bold font-headline',
  headlineLg: 'text-h1 md:text-headlineLg font-bold font-headline',
  fineBold: 'text-fine subpixel-antialiased font-bold',
};

export type HeadingProps = {
  as?: React.ElementType;
  children?: React.ReactNode;
  format?: boolean;
  size?:
    | 'display'
    | 'heading'
    | 'lead'
    | 'copy'
    | 'copyBold'
    | 'fineBold'
    | 'bodyMd'
    | 'headlineSm'
    | 'headlineMd'
    | 'headlineLg';
  width?: 'default' | 'narrow' | 'wide';
  internal?: boolean;
  isHtml?: boolean;
} & React.HTMLAttributes<HTMLHeadingElement>;

export function Heading({
  as: Component = 'h2',
  children,
  className = '',
  format,
  size = 'heading',
  width = 'default',
  internal = false,
  isHtml = false,
  ...props
}: HeadingProps) {
  const styles = clsx(
    missingClass(className, 'whitespace-') && 'whitespace-pre-wrap',
    sizes[size],
    className,
    internal && ' !font-internalDisplay',
  );

  return (
    <Component {...props} className={styles}>
      {format ? formatText(children) : children}
    </Component>
  );
}
